/*------------------------------------------------------------------
[16. Tables and Datatables]
*/
/* Generic Tables
------------------------------------
*/
.table {
  margin-top: 5px;
}
.table thead tr th {
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Montserrat';
  font-size: 13px;
  padding-top: 14px;
  padding-bottom: 14px;
  vertical-align: middle;
  border-bottom: 1px solid rgba(230, 230, 230, 0.7);
  color: rgba(44, 44, 44, 0.35);
}
.table thead tr th[class*='sorting_'] {
  color: #2c2c2c;
}
.table thead tr th:first-child {
  padding-left: 18px !important;
}
.table thead tr th .btn {
  margin-top: -20px;
  margin-bottom: -20px;
}
.table tbody tr td {
  background: #fff;
  border-bottom: 1px solid rgba(230, 230, 230, 0.7);
  border-top: 0px;
  padding: 20px;
  font-size: 13.5px;
}
.table tbody tr td .btn-tag {
  background: rgba(44, 44, 44, 0.07);
  display: inline-block;
  margin: 5px;
  border-radius: 4px;
  padding: 5px;
  color: #62605a !important;
}
.table tbody tr td .btn-tag:hover {
  background: rgba(44, 44, 44, 0.15);
}
.table tbody tr td[class*='sorting_'] {
  color: #000;
}
.table tbody tr.selected td {
  background: #fef6dd;
}
.table.table-hover tbody tr:hover td {
  background: #daeffd !important;
}
.table.table-hover tbody tr.selected:hover td {
  background: #fef6dd !important;
}
.table.table-striped tbody tr td {
  background: #fafafa !important;
}
.table.table-striped tbody tr:nth-child(2n+1) td {
  background: #fff !important;
}
.table.table-borderless tbody tr td {
  border-top: 0;
}
.table.table-condensed {
  table-layout: fixed;
}
.table.table-condensed thead tr th {
  padding-left: 20px;
  padding-right: 20px;
}
.table.table-condensed tbody tr td {
  padding-top: 12px;
  padding-bottom: 12px;
}
.table.table-condensed thead tr th,
.table.table-condensed tbody tr td,
.table.table-condensed tbody tr td * {
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table.table-condensed.table-detailed > tbody > tr.shown > td {
  background: #fef6dd;
}
.table.table-condensed.table-detailed > tbody > tr.shown > td:first-child:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td {
  background: rgba(250, 250, 250, 0.4);
  padding: 0px 40px;
}
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline {
  background: transparent;
}
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline tr,
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline td {
  background: transparent;
  font-weight: 600;
}
.table.table-condensed.table-detailed > tbody > tr.row-details > td:first-child:before {
  content: '';
}
.table.table-condensed.table-detailed > tbody > tr > td:hover {
  cursor: pointer;
}
.table.table-condensed.table-detailed > tbody > tr > td:first-child:before {
  content: "\f054";
  display: inline-block;
  margin-right: 8px;
  font-family: 'FontAwesome';
  -webkit-transition: all 0.12s linear;
  transition: all 0.12s linear;
}
.table.table-condensed.table-detailed .table-inline td {
  border: none;
  text-align: left;
}
.table.table-borderless > tbody > tr > td {
  border-bottom: 0px;
}
.fht-table {
  margin-bottom: 0 !important;
}
/* Data-tables
------------------------------------
*/
.table.dataTable.no-footer {
  border: none;
}
.dataTables_scroll:hover .dataTables_scrollBody:before {
  content: "";
  top: 0;
  height: 0;
}
.dataTables_scrollBody {
  overflow-y: auto;
  border: none !important;
}
.dataTables_scrollBody:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
  background: transparent;
}
.dataTables_wrapper .dataTables_paginate {
  margin: 0;
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: .5;
}
.dataTables_wrapper .dataTables_paginate ul > li > a {
  padding: 5px 10px;
  color: #626262;
  opacity: .35;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.dataTables_wrapper .dataTables_paginate ul > li > a:hover {
  opacity: .65;
}
.dataTables_wrapper .dataTables_paginate ul > li.next > a,
.dataTables_wrapper .dataTables_paginate ul > li.prev > a {
  opacity: 1;
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: .35;
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a:hover {
  opacity: .35;
}
.dataTables_wrapper .dataTables_info,
.dataTables_paginate.paging_bootstrap.pagination {
  margin-top: 25px;
}
.dataTables_paginate.paging_bootstrap.pagination {
  padding-top: 0;
  padding-right: 20px;
}
.dataTables_wrapper .dataTables_info {
  clear: none;
  font-size: 12px;
  padding: 0 33px;
  color: #626262;
}
.dataTables_wrapper .dataTables_paginate ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
}
.dataTables_scrollHeadInner {
  padding-right: 0 !important;
}
.export-options-container {
  position: relative;
}
.dataTables_wrapper .dataTables_paginate ul > li.active > a {
  font-weight: bold;
  color: #626262;
  opacity: 1;
}
.export-options-container a {
  color: inherit;
  opacity: 1;
}
.exportOptions .DTTT.btn-group a {
  display: block !important;
}
/* Responsive Handlers : Tables */
@media (max-width: 991px) {
  .dataTables_wrapper .dataTables_info {
    float: left;
  }
  .dataTables_paginate.paging_bootstrap.pagination {
    float: right;
  }
}
@media (max-width: 480px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_paginate.paging_bootstrap.pagination {
    float: none;
    text-align: left;
    clear: both;
    display: block;
  }
}
/* Demo classes */
.demo-table-search thead th:nth-child(1) {
  width: 20%;
}
.demo-table-search thead th:nth-child(2) {
  width: 22%;
}
.demo-table-search thead th:nth-child(3) {
  width: 24%;
}
.demo-table-search thead th:nth-child(4) {
  width: 15%;
}
.demo-table-search thead th:nth-child(5) {
  width: 19%;
}
.demo-table-dynamic thead th:nth-child(1) {
  width: 25%;
}
.demo-table-dynamic thead th:nth-child(2) {
  width: 30%;
}
.demo-table-dynamic thead th:nth-child(3) {
  width: 20%;
}
.demo-table-dynamic thead th:nth-child(4) {
  width: 25%;
}
